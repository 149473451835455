/*
Template Name: Adminto - Responsive Bootstrap 5 Admin Dashboard
Author: CoderThemes
Version: 5.1.0
Website: https://coderthemes.com/
Contact: support@coderthemes.com
File: Main Css File
*/

// Prefix for :root CSS variables
$variable-prefix: "ct-";

//Fonts
@import "custom/fonts/fonts";

@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";

@import "variables";
@import "custom-variables";
@import "light-mode";
@import "dark-mode";

@import "./node_modules/bootstrap/scss/bootstrap";

// Structure
@import "custom/structure/general";
@import "custom/structure/left-menu";
@import "custom/structure/topbar";
@import "custom/structure/page-head";
@import "custom/structure/footer";
@import "custom/structure/right-sidebar";
@import "custom/structure/layouts";
@import "custom/structure/horizontal-nav";

//Components
@import "custom/components/avatar";
@import "custom/components/helper";
@import "custom/components/social";
@import "custom/components/widgets";
@import "custom/components/print";
@import "custom/components/preloader";

//Components
@import "custom/components/alert";
@import "custom/components/accordions";
@import "custom/components/badge";
@import "custom/components/breadcrumb";
@import "custom/components/buttons";
@import "custom/components/custom-checkbox";
@import "custom/components/card";
@import "custom/components/dropdown";
@import "custom/components/forms";
@import "custom/components/modal";
@import "custom/components/nav";
@import "custom/components/pagination";
@import "custom/components/popover";
@import "custom/components/progress";
@import "custom/components/reboot";
@import "custom/components/tables";
@import "custom/components/type";
@import "custom/components/utilities";
@import "custom/components/carousel"; //carousel
@import "custom/components/typeahead"; //typeahead

//Pages
@import "custom/pages/authentication";
@import "custom/pages/chat";
@import "custom/pages/components-demo";
@import "custom/pages/error";
@import "custom/pages/logout";
@import "custom/pages/faq";
@import "custom/pages/maintenance";
@import "custom/pages/timeline";
@import "custom/pages/email";
@import "custom/pages/sitemap";
@import "custom/pages/search-results";
@import "custom/pages/pricing";
@import "custom/pages/coming-soon";
@import "custom/pages/profile";
@import "custom/pages/task";
@import "custom/pages/kanbanboard";
@import "custom/pages/ecommerce";
@import "custom/pages/landing"; // landing

// Plugins
@import "custom/plugins/waves";
@import "custom/plugins/ion-rangeslider";
@import "custom/plugins/calendar";
@import "custom/plugins/colorpicker";
@import "custom/plugins/sparkline-chart";
@import "custom/plugins/datatable";
@import "custom/plugins/daterange";
@import "custom/plugins/form-wizard";
@import "custom/plugins/select2";
@import "custom/plugins/slimscroll";
@import "custom/plugins/toaster";
@import "custom/plugins/sweetalert";
@import "custom/plugins/selectize";
@import "custom/plugins/tippy";
@import "custom/plugins/hopscotch";
@import "custom/plugins/flot";
@import "custom/plugins/morris";
@import "custom/plugins/chartjs";
@import "custom/plugins/chartist";
@import "custom/plugins/ricksaw";
@import "custom/plugins/responsive-table";
@import "custom/plugins/tablesaw";
@import "custom/plugins/multiple-select";
@import "custom/plugins/autocomplete";
@import "custom/plugins/bootstrap-touchspin";
@import "custom/plugins/parsley";
@import "custom/plugins/flatpickr";
@import "custom/plugins/clockpicker";
@import "custom/plugins/quilljs-editor";
@import "custom/plugins/dropzone";
@import "custom/plugins/dropify";
@import "custom/plugins/x-editable";
@import "custom/plugins/google-maps";
@import "custom/plugins/vector-maps";
@import "custom/plugins/mapeal-maps";
@import "custom/plugins/datepicker";
@import "custom/plugins/dragula";
@import "custom/plugins/jstree";
@import "custom/plugins/bootstrap-taginput";
@import "custom/plugins/rangeslider"; // range slider
@import "custom/plugins/joyride"; //joyride
@import "custom/plugins/apexcharts"; // apexcharts
